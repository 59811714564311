import { format, sub } from 'date-fns'
import {
  boolean,
  convert,
  count,
  date,
  descending,
  distinct,
  entity,
  equals,
  greater,
  greaterOrEqual,
  isNull,
  less,
  lessOrEqual,
  notNull,
  number,
  query,
  string,
  truthy,
} from '~/prix'

export default function latestComparisonWeeksQuery({
  stateAbbreviation,
  stateId,
  dataSourceId,
  isPortfolioEad,
  lastRegisterDate,
}: {
  stateAbbreviation?: string
  stateId?: string
  dataSourceId: number
  isPortfolioEad?: boolean
  lastRegisterDate?: any
}) {
  const isEad = dataSourceId === 5 || dataSourceId === 1
  const currentDate = new Date()
  const now = currentDate ? format(currentDate, 'yyyy-MM-dd') : null

  const sevenDaysAgoDate = lastRegisterDate
    ? sub(new Date(lastRegisterDate), {
        days: 6,
      })
    : null
  const sevenDaysAgo = sevenDaysAgoDate ? format(sevenDaysAgoDate, 'yyyy-MM-dd') : null

  const fourteenDaysAgoDate = lastRegisterDate
    ? sub(new Date(lastRegisterDate), {
        days: 13,
      })
    : null

  const fourteenDaysAgo = fourteenDaysAgoDate ? format(fourteenDaysAgoDate, 'yyyy-MM-dd') : null

  const prixFilters = [
    isNull(entity('legalEntity').property('deletedAt')),
    notNull(entity('legalEntityGeoprocessing').property('stateId')),

    !isEad && stateAbbreviation && stateAbbreviation !== undefined
      ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
      : null,

    isEad && stateAbbreviation && stateAbbreviation !== undefined
      ? equals(entity('legalEntity').property('state'), string().value(stateAbbreviation))
      : null,

    stateId && stateId !== undefined
      ? equals(
          entity(isEad ? 'legalEntityGeoprocessing' : 'legalEntityAttendance').property('stateId'),
          string().value(stateId),
        )
      : null,

    isEad
      ? null
      : equals(
          entity('legalEntityAttendanceDataSource').property('dataSourceId'),
          number().value(dataSourceId === 1 ? 5 : dataSourceId),
        ),

    isPortfolioEad === true && dataSourceId === 1
      ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
      : null,
  ].filter(truthy)

  function timeFilters(weekRange: 'lastWeek' | 'secondLastWeek') {
    switch (weekRange) {
      case 'lastWeek':
        return [
          isEad && sevenDaysAgo
            ? greaterOrEqual(
                entity('courseRegisterIndividual').property('registerDate'),
                date().value(`${sevenDaysAgo}`),
              )
            : sevenDaysAgo
            ? greaterOrEqual(
                entity('legalEntityAttendance').property('startDate'),
                date().value(`${sevenDaysAgo}`),
              )
            : null,

          isEad && now
            ? lessOrEqual(
                entity('courseRegisterIndividual').property('registerDate'),
                date().value(String(now)),
              )
            : lessOrEqual(
                entity('legalEntityAttendance').property('startDate'),
                date().value(String(now)),
              ),
        ].filter(truthy)
      case 'secondLastWeek':
        return [
          isEad && fourteenDaysAgo
            ? greater(
                entity('courseRegisterIndividual').property('registerDate'),
                date().value(`${fourteenDaysAgo}`),
              )
            : fourteenDaysAgo
            ? greater(
                entity('legalEntityAttendance').property('startDate'),
                date().value(`${fourteenDaysAgo}`),
              )
            : null,

          isEad && sevenDaysAgo
            ? less(
                entity('courseRegisterIndividual').property('registerDate'),
                date().value(`${sevenDaysAgo}`),
              )
            : sevenDaysAgo
            ? less(
                entity('legalEntityAttendance').property('startDate'),
                date().value(`${sevenDaysAgo}`),
              )
            : null,
        ]
    }
  }

  const latestWeek = query('legalEntity')
    .select({
      date: isEad
        ? convert(entity('courseRegisterIndividual').property('registerDate'), date())
        : convert(entity('legalEntityAttendance').property('startDate'), date()),
      total: isEad
        ? count(distinct(entity('courseRegisterIndividual').property('id')))
        : count(distinct(entity('legalEntityAttendance').property('id'))),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    [isEad ? 'join' : 'dummy']({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'inner',
    })
    [isEad ? 'join' : 'dummy']({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'inner',
    })
    [isEad ? 'dummy' : 'join']({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'inner',
    })
    [isEad ? 'dummy' : 'join']({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    [stateAbbreviation !== undefined && !isEad ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .where(...prixFilters, ...timeFilters('lastWeek').filter(truthy))
    .order(descending('date'))

  const secondLastWeek = query('legalEntity')
    .select({
      date: isEad
        ? convert(entity('courseRegisterIndividual').property('registerDate'), date())
        : convert(entity('legalEntityAttendance').property('startDate'), date()),
      total: isEad
        ? count(distinct(entity('courseRegisterIndividual').property('id')))
        : count(distinct(entity('legalEntityAttendance').property('id'))),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    [isEad ? 'join' : 'dummy']({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'inner',
    })
    [isEad ? 'join' : 'dummy']({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'inner',
    })
    [isEad ? 'dummy' : 'join']({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'inner',
    })
    [isEad ? 'dummy' : 'join']({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    [stateAbbreviation !== undefined && !isEad ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .where(...prixFilters, ...timeFilters('secondLastWeek').filter(truthy))
    .order(descending('date'))

  return {
    latestWeek,
    secondLastWeek,
  }
}
