/* globals caches */
import { add, format, subMonths, subYears } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { colors } from '~/design'
import eachSourceAttendanceDatesQuery from '~/packages/legalEntityAttendance/attendanceDates/eachSourceAttendanceDates.query'
import AttendanceField from '~/packages/legalEntityAttendance/attendanceField'
import {
  AppError,
  boolean,
  descending,
  entity,
  enumerated,
  equals,
  like,
  number,
  query,
  some,
  string,
  truthy,
} from '~/prix'
import unaccent from '~/prix/functions/unaccent'
import { QueryBase } from '~/prix/query'
import { Button } from '~/prix/react/components/button'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import SelectFromQuery from '~/prix/react/components/form/inputs/selectFromQuery'
import useItems from '~/prix/react/hooks/items'
import { Filter } from './disproportionateAttentionMapLevels.data'
import AlertIcon from '~/components/icons/ui/16px_alert.svg'
import TopCard from '~/components/topCard'
import useMedia from 'react-use/lib/useMedia'
import { eadOptions } from '~/packages/legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenuTypes'
import DateField from '~/prix/react/components/form/inputs/dateTimeField'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .containers {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 16px 8px 8px;

    fieldset.section-container {
      display: flex;
      flex-direction: column;
      background: #fff;
      box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.2);
      border: solid 1px rgba(221, 221, 221, 1);

      padding: 15px;
      border-radius: 4px;
      margin-bottom: 10px;

      legend {
        font-size: 0.95rem;
        padding: 0 5px;
        margin: 0;
      }

      .item-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        label {
          color: ${colors.sebraeBlue};
          font-weight: normal;
        }

        &.results-container {
          margin-top: 0;

          output {
            margin-top: 2.5px;
          }
        }

        &.extra-container {
          margin-top: 0;

          output {
            margin-top: 2.5px;
            margin-bottom: 7.5px;
          }
        }
      }
      .item-additional-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        small {
          margin-top: 2.5px;
        }

        label {
          color: ${colors.sebraeBlue};
          font-weight: normal;
          margin-top: 10px;
        }
        &.extra-container {
          margin-top: 0;

          output {
            margin-top: 2.5px;
          }
        }
      }
    }
  }

  .line-container {
    margin: 10px 0px;
  }

  .line-container-label {
    width: 56px;
  }

  .line {
    display: block;
    height: 1px;
    background: transparent;
    width: 100%;
    border: none;
    margin-top: 5px;
    margin-bottom: 10px;
    border-top: solid 1px rgba(221, 221, 221, 1);
  }

  .legend-container {
    margin: 16px 16px 0;
    height: 120px;

    label {
      color: ${colors.sebraeBlue};
      font-weight: normal;
    }

    div {
      display: flex;
      .column-background {
        width: 25px;
        flex-direction: column;
        align-items: left;
        margin-right: 10px;
      }
      .column {
        flex: 1;
        flex-direction: column;
        height: 65px;
      }

      .row {
        flex-direction: row;
        align-items: center;
        padding: 5px;

        .row-span {
          font-size: 14px;
          color: #363636;
          font-weight: normal;
          margin-bottom: 16px;
        }
      }
    }
  }

  .alert-info {
    display: flex;
    flex-direction: row;
    background-color: #f8fbff;
    color: #000;
    border: 1px solid #e7f2ff;
    border-radius: 4px;
    justify-content: center;

    .icon-wrapper {
      margin-top: 2px;
      flex-direction: column;
      padding: 10px;
    }

    .span-wrapper {
      margin: auto 0;
      flex-direction: column;
      color: #212529;
      font-size: 13px;
    }
  }

  @media (max-width: 768px) {
    padding: 0;

    .containers {
      margin-top: -32px;
      padding: 0 32px;
      z-index: 5;
    }

    .alert-info,
    .legend-container {
      padding: 0 32px;
    }
  }
`

const SectionTitle = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: var(--p-action, #0f438a);
  display: flex;
  height: 52px;
  flex-direction: column;
  justify-content: center;
  margin: 0 2px;

  span {
    color: #fff;
    text-align: center;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    font-size: 19px;
  }
`

interface MapMenu {
  onChangeFilter: (newFilter: Filter | null) => void
  filter: Filter | null
  isLoading: boolean
  loadingMessage?: QueryBase[]
  toggleMenuVisibility: () => void
  toggleViewForAll: Function | null
  error?: AppError
}

export default function MapMenu({
  onChangeFilter,
  filter,
  isLoading,
  error,
  toggleViewForAll,
}: MapMenu) {
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [attendanceSourceActive, setAttendanceSourceActive] = useState<string | undefined>(
    undefined,
  )

  const isMobile = useMedia('(max-width: 768px)')

  const size = {
    micro: 'Micro empresa',
    small: 'Empresa de pequeno porte',
    isMei: 'Microempreendedor Individual',
    other: 'Demais',
  }

  const eachSourceAttendanceDates = useItems(() => eachSourceAttendanceDatesQuery(), [isLoading], {
    cache: 60 * 60 * 8,
    autoLoad: isLoading === false,
  })

  const eachSourceAttendanceDatesFormatted: any = eachSourceAttendanceDates.items?.map(
    (item: any) => {
      const maxAttendanceDate = format(new Date(item.maxAttendanceDate), 'yyyy-MM-dd')
      const minAttendanceDate = format(new Date(item.minAttendanceDate), 'yyyy-MM-dd')

      return {
        value: item.dataSourceId,
        label: item.dataSourceName,
        minAttendanceDate,
        maxAttendanceDate,
        startDate: format(
          subYears(add(new Date(maxAttendanceDate), { hours: 3 }), 1),
          'yyyy-MM-dd',
        ),
        period: `Dados de ${format(
          add(new Date(minAttendanceDate), { hours: 3 }),
          'dd/MM/yyyy',
        )} à ${format(add(new Date(maxAttendanceDate), { hours: 3 }), 'dd/MM/yyyy')}`,
      }
    },
  )

  useEffect(() => {
    if (eachSourceAttendanceDatesFormatted && filter?.attendanceSource && !startDate && !endDate) {
      eachSourceAttendanceDatesFormatted.map((item: any) => {
        if (item?.value === Number(filter?.attendanceSource)) {
          const endDate = item.maxAttendanceDate ? item.maxAttendanceDate : null
          setEndDate(endDate)
          const startDate = item.startDate ? item.startDate : null
          setStartDate(startDate)
        }
      })
    }
  }, [eachSourceAttendanceDatesFormatted, filter?.attendanceSource, startDate, endDate])

  useEffect(() => {
    if (startDate && endDate && attendanceSourceActive) {
      handleOption(
        filter?.size,
        filter?.cnaeId,
        filter?.cnaeName,
        `${startDate},${endDate}`,
        filter?.attendanceSource,
        filter?.eadOption,
        filter?.courseProductCode,
        filter?.courseProductName,
      )
    }
  }, [startDate, endDate, attendanceSourceActive])

  useEffect(() => {
    if (filter?.attendanceSource !== attendanceSourceActive) {
      setStartDate('')
      setEndDate('')
    }
  }, [filter?.attendanceSource, attendanceSourceActive])

  const cnaeQueryFactory = useCallback((input: string) => {
    const inputWithWildcards = `%${input.replace(/\s/g, '%')}%`

    const cnaeNestSeparator = '>'
    return query('cnaeSection')
      .select({
        id: entity('cnaeSection').property('cnaes'),
        name: entity('cnaeSection').property('description'),
        subtitle: string().value('Seção'),
      })
      .where(
        like(
          unaccent(entity('cnaeSection').property('description')),
          unaccent(string().value(inputWithWildcards)),
        ),
      )
      .union(
        query('cnaeDivision')
          .select({
            id: entity('cnaeDivision').property('ibgeCode'),
            name: entity('cnaeDivision').property('description'),
            subtitle: string().value(`Seção ${cnaeNestSeparator} Divisão`),
          })
          .where(
            like(
              unaccent(entity('cnaeDivision').property('description')),
              unaccent(string().value(inputWithWildcards)),
            ),
          ),
        query('cnaeGroup')
          .select({
            id: entity('cnaeGroup').property('ibgeCode'),
            name: entity('cnaeGroup').property('description'),
            subtitle: string().value(
              `Seção ${cnaeNestSeparator} Divisão ${cnaeNestSeparator} Grupo`,
            ),
          })
          .where(
            ...[
              !isNaN(Number(input))
                ? like(entity('cnaeGroup').property('ibgeCode'), string().value(inputWithWildcards))
                : like(
                    unaccent(entity('cnaeGroup').property('description')),
                    unaccent(string().value(inputWithWildcards)),
                  ),
            ].filter(truthy),
          ),
        query('cnaeClass')
          .select({
            id: entity('cnaeClass').property('ibgeCode'),
            name: entity('cnaeClass').property('description'),
            subtitle: string().value(
              `Seção ${cnaeNestSeparator} Divisão ${cnaeNestSeparator} Grupo ${cnaeNestSeparator} Classe`,
            ),
          })
          .where(
            ...[
              !isNaN(Number(input))
                ? like(entity('cnaeClass').property('ibgeCode'), string().value(inputWithWildcards))
                : like(
                    unaccent(entity('cnaeClass').property('description')),
                    unaccent(string().value(inputWithWildcards)),
                  ),
            ].filter(truthy),
          ),
      )
      .limit(21)
  }, [])

  const courseProductQueryFactory = useCallback(
    (input: string) =>
      query('courseProduct')
        .select({
          productCode: entity('courseProduct').property('productCode'),
          portfolioSasCode: entity('courseProduct').property('portfolioSasCode'),
          productName: entity('courseProduct').property('productName'),
          cnpjCount: entity('courseProduct').property('cnpjCount'),
        })
        .where(
          ...[
            some(
              like(
                entity('courseProduct').property('portfolioSasCode'),
                string().value(`%${input.replace(/\s/g, '%')}%`),
              ),
              like(
                unaccent(entity('courseProduct').property('productName')),
                unaccent(string().value(`%${input.replace(/\s/g, '%')}%`)),
              ),
              equals(
                entity('courseProduct').property('productCode'),
                number().value(Number(input)),
              ),
            ),
            equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true)),
          ].filter(truthy),
        )
        .order(descending('cnpjCount')),
    [],
  )

  const handleOption = useCallback(
    (
      size?: string,
      cnaeId?: string,
      cnaeName?: string,
      attendancePeriod?: string,
      attendanceSource?: string,
      eadOption?: string,
      courseProductCode?: string,
      courseProductName?: string,
    ) => {
      if (!attendanceSource) {
        onChangeFilter({
          size,
          cnaeId,
          cnaeName,
          attendancePeriod: undefined,
          attendanceSource: undefined,
          eadOption: undefined,
          courseProductCode: undefined,
          courseProductName: undefined,
        })

        return
      }

      onChangeFilter({
        size,
        cnaeId,
        cnaeName,
        attendancePeriod: attendanceSource && attendancePeriod ? attendancePeriod : undefined,
        attendanceSource,
        eadOption: eadOption ? eadOption : attendanceSource === '5' ? 'all' : undefined,
        courseProductCode,
        courseProductName,
      })

      setAttendanceSourceActive(attendanceSource ? attendanceSource : '')
    },
    [onChangeFilter, startDate, endDate, eachSourceAttendanceDatesFormatted],
  )

  return (
    <React.Fragment>
      <Wrapper>
        {isMobile && <TopCard title='Atendimento desproporcional' />}
        <div className='containers'>
          <SectionTitle>
            <span>Filtrar Empresas</span>
          </SectionTitle>
          <fieldset className='section-container'>
            <div className='item-container'>
              <label>Porte</label>
              <div style={{ marginTop: '4px' }}>
                <EnumeratedField
                  fieldSchema={enumerated(size).isOptional()}
                  meta={{ isDisabled: false, placeholder: 'Selecione...' }}
                  value={filter?.size}
                  onDirectChange={(value: string) =>
                    handleOption(
                      value,
                      filter?.cnaeId,
                      filter?.cnaeName,
                      filter?.attendancePeriod,
                      filter?.attendanceSource,
                      filter?.eadOption,
                      filter?.courseProductCode,
                      filter?.courseProductName,
                    )
                  }
                />
              </div>
            </div>

            <div className='item-container'>
              <div style={{ marginTop: '6px' }} />
              <label>Fonte de dados</label>
              <div style={{ marginTop: '4px' }}>
                <AttendanceField
                  fieldSchema={enumerated(eachSourceAttendanceDatesFormatted).isOptional()}
                  value={filter?.attendanceSource}
                  onDirectChange={(value: string) => {
                    setStartDate('')
                    setEndDate('')
                    handleOption(
                      filter?.size,
                      filter?.cnaeId,
                      filter?.cnaeName,
                      undefined,
                      value,
                      filter?.eadOption,
                      filter?.courseProductCode,
                      filter?.courseProductName,
                    )
                  }}
                />
              </div>

              {filter && eachSourceAttendanceDatesFormatted && filter.attendanceSource === '5' ? (
                <div className='item-container'>
                  <div style={{ marginTop: '6px' }} />
                  <label>Nível</label>
                  <div style={{ marginTop: '4px' }}>
                    <EnumeratedField
                      fieldSchema={enumerated(eadOptions).isOptional()}
                      value={filter?.eadOption ? filter.eadOption : 'all'}
                      onDirectChange={(value: any) =>
                        handleOption(
                          filter?.size,
                          filter?.cnaeId,
                          filter?.cnaeName,
                          filter?.attendancePeriod,
                          filter?.attendanceSource,
                          value,
                          filter?.courseProductCode,
                          filter?.courseProductName,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}

              {filter && filter.attendanceSource === '5' && filter?.eadOption === 'individual' ? (
                <div className='item-container'>
                  <div style={{ marginTop: '6px' }} />
                  <label>Curso</label>
                  <div style={{ marginTop: '4px' }}>
                    <SelectFromQuery
                      queryFactory={courseProductQueryFactory}
                      idProperty='productCode'
                      labelProperty='productName'
                      preLoad
                      value={
                        filter.courseProductCode
                          ? {
                              productCode: filter.courseProductCode || null,
                              productName: filter.courseProductName || null,
                            }
                          : {
                              productCode: '303',
                              productName: 'Gestão financeira',
                            }
                      }
                      onDirectChange={value =>
                        handleOption(
                          filter?.size,
                          filter?.cnaeId,
                          filter?.cnaeName,
                          filter?.attendancePeriod,
                          filter?.attendanceSource,
                          filter?.eadOption,
                          value?.productCode as unknown as string | undefined,
                          value?.productName as unknown as string | undefined,
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}
              <div style={{ marginTop: '6px' }} />
            </div>

            {startDate && filter?.attendanceSource && eachSourceAttendanceDatesFormatted ? (
              <React.Fragment>
                <div className='item-container'>
                  <DateField
                    id='start-date-field'
                    value={startDate}
                    label='Data inicial'
                    onDirectChange={date => {
                      setStartDate(date ? format(date, 'yyyy-MM-dd') : startDate)
                    }}
                    placeholder='Início'
                    meta={{
                      maxDate: new Date(),
                      minDate: subYears(new Date(), 3),
                    }}
                    isRequired
                  />
                </div>
                <div className='item-container'>
                  <DateField
                    id={'end-date-field'}
                    value={endDate}
                    label='Data final'
                    onDirectChange={date => {
                      setEndDate(date ? format(date, 'yyyy-MM-dd') : endDate)
                    }}
                    placeholder='Final'
                    meta={{
                      maxDate: new Date(),
                      minDate: subYears(new Date(), 3),
                    }}
                    isRequired
                  />
                </div>
              </React.Fragment>
            ) : null}

            <div className='item-container'>
              <div style={{ marginTop: '6px' }} />
              <label>Atividade Econômica</label>
              <div style={{ marginTop: '4px' }}>
                <SelectFromQuery
                  queryFactory={cnaeQueryFactory}
                  idProperty='id'
                  labelProperty='name'
                  preLoad
                  value={
                    filter?.cnaeId
                      ? { id: filter.cnaeId, name: filter.cnaeName as string }
                      : undefined
                  }
                  onDirectChange={(value: any) =>
                    handleOption(
                      filter?.size,
                      value?.id,
                      value?.name,
                      filter?.attendancePeriod,
                      filter?.attendanceSource,
                      filter?.eadOption,
                      filter?.courseProductCode,
                      filter?.courseProductName,
                    )
                  }
                />
              </div>
            </div>
            {toggleViewForAll ? (
              <div className='item-container' style={{ marginTop: '15px' }}>
                <Button
                  onClick={evt => {
                    evt.preventDefault()
                    toggleViewForAll()
                  }}
                  disabled={isLoading}
                >
                  Ver para o Brasil
                </Button>
              </div>
            ) : null}
          </fieldset>
          <div style={{ flex: 1 }}></div>

          <div className='alert-info'>
            <div className='icon-wrapper'>
              <AlertIcon fill={'#f1c40f'} width={18} height={18} />
            </div>
            <div className='span-wrapper'>Categorias de cnae separadas por cores individuais.</div>
          </div>
        </div>
      </Wrapper>
    </React.Fragment>
  )
}
