import {
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
} from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import useItems from '~/prix/react/hooks/items'
import disproportionateAttentionQuery from './disproportionateAttention.query'
import { QueryBase } from '~/prix/query'
import { eadOptions } from '~/packages/legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenuTypes'

export default function useDisproportionateAttention({
  geoFilter,
  filters,
  isEnabled,
}: {
  geoFilter: {
    geoLevel: GeoLegalEntitiesPossibilities | null
    geoLevelId: string | null
    geoAggregate: GeoChildrenLegalEntitiesPossibilities | null
  }
  filters: {
    size: 'EPP' | 'MEI' | 'ME' | null
    economicActivity: string | null
    dataSourceId: number | null
    attendancePeriod: string | null
    eadOption: keyof typeof eadOptions | null
    courseProductCode: string | null
  }
  isEnabled: boolean
}) {
  console.log('* ** * * **************** **** ** * ***************************')
  console.log('geoFiler', geoFilter)
  console.log('filters', filters)

  const { publicQuery, attendanceQuery } = disproportionateAttentionQuery(geoFilter, filters)

  const publicResult = useItems(
    () => (isEnabled ? (publicQuery as QueryBase) : (null as never)),
    [isEnabled, JSON.stringify(geoFilter), JSON.stringify(filters)],
    {
      cache: 60 * 60 * 24,
      autoLoad: true,
    },
  )

  const attendanceResult = useItems(
    () => (isEnabled ? (attendanceQuery as QueryBase) : (null as never)),
    [isEnabled, JSON.stringify(geoFilter), JSON.stringify(filters)],
    {
      cache: 60 * 60 * 24,
      autoLoad: true,
    },
  )

  return {
    publicResult,
    attendanceResult,
    isLoading: publicResult.isLoading || attendanceResult.isLoading,
  }
}
