import {
  count,
  entity,
  query,
  string,
  truthy,
  equals,
  ternary,
  between,
  number,
  boolean,
} from '~/prix'
import { createDefaultDateFilter, getDateRangeFilter } from './utils'
import { FilterHighlight } from '../legalEntityGeoprocessingMapLevels.data'

export default function legalEntityAttendanceCourseDropoutQuery({
  groupColumn,
  idColumn,
  id,
  filter: filterIncoming,
  highlight,
}: {
  groupColumn: string
  idColumn: string | null
  id: string | null
  filter?: FilterHighlight | null
  highlight?: FilterHighlight | null
}) {
  const filter = filterIncoming ? getDateRangeFilter(filterIncoming) : createDefaultDateFilter()
  const highlightDates = highlight ? getDateRangeFilter(highlight) : createDefaultDateFilter()

  return query('courseEvasion')
    .select({
      geoId:
        groupColumn === 'macroRegionId'
          ? entity('macroRegion').property('id')
          : entity('state').property('id'),
      count: count(entity('courseEvasion').property('productCode')),
      highlight: count(
        ternary(
          between(
            entity('courseEvasion').property('coursePercentualConclusion'),
            number().value(0.1),
            number().value(75),
          ),
          number().value(1),
          number().value(null as unknown as number),
        ),
      ),
    })
    .join({
      current: entity('courseEvasion').property('uf'),
      target: entity('state').property('abbreviation'),
      join: 'inner',
    })
    .join({
      current: entity('state').property('macroRegionId'),
      target: entity('macroRegion').property('id'),
      join: 'inner',
    })
    [highlight?.eadOption === 'portfolio' ? 'join' : 'dummy']({
      current: entity('courseEvasion').property('productCode'),
      target: entity('courseProduct').property('productCode'),
      join: 'inner',
    })
    .where(
      ...[
        // Portfolio
        highlight?.eadOption === 'portfolio'
          ? equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true))
          : null,

        // Individual
        highlight?.eadOption === 'individual' && highlight.courseProduct
          ? equals(
              entity('courseEvasion').property('productCode'),
              number().value(Number(highlight?.courseProduct)),
            )
          : null,

        highlight?.eadOption === 'individual'
          ? between(
              entity('courseEvasion').property('registerDate'),
              string().value(filter.startDate),
              string().value(filter.endDate),
            )
          : between(
              entity('courseEvasion').property('registerDate'),
              string().value(highlightDates.startDate),
              string().value(highlightDates.endDate),
            ),
      ].filter(truthy),
    )
    .limit(10000)
}
