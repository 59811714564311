import { extractDates } from '~/packages/legalEntityGeoprocessing/map/definedQueries/utils'
import {
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
} from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import { eadOptions } from '~/packages/legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenuTypes'
import {
  between,
  boolean,
  count,
  descending,
  entity,
  equals,
  every,
  isNull,
  number,
  query,
  some,
  string,
  truthy,
} from '~/prix'
import left from '~/prix/functions/left'

export default function disproportionateAttentionQuery(
  geoFilter: {
    geoLevel: GeoLegalEntitiesPossibilities | null
    geoLevelId: string | null
    geoAggregate: GeoChildrenLegalEntitiesPossibilities | null
  },
  filters: {
    size: 'EPP' | 'MEI' | 'ME' | null
    economicActivity: string | null
    dataSourceId: number | null
    attendancePeriod: string | null
    eadOption: keyof typeof eadOptions | null
    courseProductCode: string | null
  },
) {
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando a legal_entity por porte. EPP, MEI e EE.
   * @returns Operador de comparação ou nulo
   */
  function filterBySize() {
    if (filters.size) {
      switch (filters.size) {
        case 'EPP':
          return equals(entity('legalEntity').property('size'), string().value('small'))
        case 'ME':
          return equals(entity('legalEntity').property('size'), string().value('micro'))
        case 'MEI':
          return equals(entity('legalEntity').property('isMei'), boolean().value(true))
      }
    }
    return null
  }
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando pelo seu main_cnae (Em níveis de seção, divisão, grupo e classe.).
   * @returns Operador de comparação ou nulo
   */
  function filterByEconomicActivity() {
    if (filters.economicActivity) {
      const cnaes = filters.economicActivity.split(',').map(item => {
        return equals(
          left(entity('cnae').property('id'), number().value(item.length)),
          string().value(item),
        )
      })
      return some(...cnaes)
    }
  }
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando os atendimentos pelo fonte de dados.
   * @returns Operador de comparação ou nulo
   */
  function filterByDataSource() {
    if (filters.dataSourceId) {
      if (filters.attendancePeriod === null) {
        return [
          equals(
            entity('legalEntityAttendanceDataSource').property('dataSourceId'),
            number().value(filters.dataSourceId),
          ),
        ]
      } else {
        const [startDate, endDate] = extractDates(filters.attendancePeriod)

        return [
          every(
            between(
              entity('legalEntityAttendance').property('startDate'),
              startDate
                ? string().value(String(startDate))
                : entity('dataSource').property('lastYearDate'),
              endDate ? string().value(String(endDate)) : entity('dataSource').property('endDate'),
            ),
            equals(
              entity('legalEntityAttendanceDataSource').property('dataSourceId'),
              number().value(filters.dataSourceId),
            ),
          ),
        ]
      }
    }
    return []
  }
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando os atendimentos pelo fonte de dados do EAD, Portfólio ou individual.
   * @returns Operador de comparação ou nulo
   */
  function filterByEadOptions() {
    if (filters.eadOption) {
      switch (filters.eadOption) {
        case 'all':
          return [null]
        case 'portfolio':
          return [
            equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true)),
          ]
        case 'individual':
          if (filters.courseProductCode) {
            return [
              equals(
                entity('courseProduct').property('productCode'),
                string().value(filters.courseProductCode),
              ),
            ]
          } else {
            return [null]
          }
        default:
          return [null]
      }
    }
    return [null]
  }
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando a query pelo seu nível geográfico (Estado, cidade, etc...).
   * @returns Operador de comparação ou nulo
   */
  function filterByGeoFilter() {
    let geoFilterWhere = undefined
    let geoFilterAggregate = undefined

    if (geoFilter.geoAggregate) {
      switch (geoFilter.geoAggregate) {
        case 'countries':
          geoFilterAggregate = entity('legalEntityGeoprocessing').property(`countryId`)
          break
        case 'cities':
          geoFilterAggregate = entity('legalEntityGeoprocessing').property(`cityId`)
          break
        case 'macroRegions':
          geoFilterAggregate = entity('legalEntityGeoprocessing').property(`macroRegionId`)
          break
        case 'mesoRegions':
          geoFilterAggregate = entity('legalEntityGeoprocessing').property(`mesoRegionId`)
          break
        case 'microRegions':
          geoFilterAggregate = entity('legalEntityGeoprocessing').property(`microRegionId`)
          break
        case 'neighborhoods':
          geoFilterAggregate = entity('legalEntityGeoprocessing').property(`neighborhoodId`)
          break
        case 'states':
          geoFilterAggregate = entity('legalEntityGeoprocessing').property(`stateId`)
          break
      }
    }
    if (geoFilter.geoLevel && geoFilter.geoLevelId) {
      geoFilterWhere = equals(
        entity('legalEntityGeoprocessing').property(`${geoFilter.geoLevel}Id`),
        string().value(geoFilter.geoLevelId),
      )
    }
    return {
      geoFilterWhere,
      geoFilterAggregate,
    }
  }

  /**
   * Filtros unidos para as queries de atendimento e legal_entity para reutilização posterior.
   */
  const prixApiFilters = (fromSource: 'attendance' | 'public') =>
    [
      isNull(entity('legalEntity').property('deletedAt')),
      fromSource === 'public'
        ? some(
            equals(entity('legalEntity').property('registrationStatus'), string().value('active')),
            equals(entity('legalEntity').property('registrationStatus'), string().value('inapt')),
          )
        : null,
      filterBySize(),
      filterByEconomicActivity(),
      filterByGeoFilter().geoFilterWhere,
    ].filter(truthy)
  /**
   * Filtros unidos para filtrar os atendimentos em específico.
   */
  const prixApiFiltersAttendance = [
    isNull(entity('legalEntityAttendance').property('deletedAt')),
    ...prixApiFilters('attendance'),
    ...filterByDataSource(),
    ...filterByEadOptions(),
  ].filter(truthy)

  const publicQuery = query('legalEntity')
    .select({
      cnaeId: entity('cnae').property('id'),
      cnaeDescription: entity('cnae').property('description'),
      count: count(entity('legalEntity').property('id')),
      geoId: filterByGeoFilter().geoFilterAggregate,
    })
    .join({
      current: entity('legalEntity').property('mainCnaeId'),
      target: entity('cnae').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    .where(...prixApiFilters('public'))
    .order(descending('count'))

  const attendanceQuery = query('legalEntity')
    .select({
      cnaeId: entity('cnae').property('id'),
      cnaeDescription: entity('cnae').property('description'),
      count: count(entity('legalEntity').property('id')),
      geoId: filterByGeoFilter().geoFilterAggregate,
    })
    .join({
      current: entity('legalEntity').property('mainCnaeId'),
      target: entity('cnae').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'inner',
    })
    [filters.dataSourceId === 5 ? 'join' : 'dummy']({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'inner',
    })
    [filters.dataSourceId === 5 ? 'join' : 'dummy']({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'inner',
    })
    .where(...prixApiFiltersAttendance)
    .order(descending('count'))

  return {
    publicQuery,
    attendanceQuery,
  }
}
