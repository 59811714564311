import { attendancePeriodLastYearFormatted } from '~/packages/legalEntityGeoprocessing/map/definedQueries/utils'
import {
  entity,
  query,
  count,
  distinct,
  equals,
  truthy,
  string,
  number,
  isNull,
  descending,
  every,
  between,
} from '~/prix'

export default function mostUsedCitiesQuery({
  stateAbbreviation,
  stateId,
  dataSourceId,
}: {
  stateAbbreviation?: string
  stateId?: string
  dataSourceId: number
}) {
  return query('legalEntityGeoprocessing')
    .select({
      geoId: entity('legalEntityGeoprocessing').property('cityId'),
      geoName: entity('city').property('name'),
      totalLegalEntity: count(
        distinct(entity('legalEntityGeoprocessing').property('legalEntityId')),
      ),
      totalAttendances: count(
        every(
          between(
            entity('legalEntityAttendance').property('startDate'),
            string().value(attendancePeriodLastYearFormatted.startDate),
            string().value(attendancePeriodLastYearFormatted.endDate),
          ),
          equals(
            entity(`legalEntityAttendanceDataSource`).property(`dataSourceId`),
            number().value(dataSourceId),
            entity('legalEntityGeoprocessing').property('legalEntityId'),
          ),
        ),
      ),
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,

        stateId && stateId !== undefined
          ? equals(entity('state').property('id'), string().value(stateId))
          : null,
      ].filter(truthy),
    )
    .order(descending('totalAttendances'))
}
