import {
  count,
  entity,
  equals,
  query,
  string,
  truthy,
  distinct,
  between,
  number,
  isNull,
} from '~/prix'
import { getDateRangeFilter } from './utils'
import { FilterHighlight } from '../legalEntityGeoprocessingMapLevels.data'

export default function legalEntityAttendanceAppSebraeQuery({
  groupColumn,
  idColumn,
  id,
  filter: filterIncoming,
}: {
  groupColumn: string
  idColumn: string | null
  id: string | null
  filter?: FilterHighlight | null
}) {
  const filter = filterIncoming ? getDateRangeFilter(filterIncoming) : null

  return query('legalEntityGeoprocessing')
    .select({
      geoId: entity('legalEntityGeoprocessing').property(groupColumn),
      count: count(distinct(entity('legalEntityGeoprocessing').property('legalEntityId'))),
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`legalEntityAttendance`).property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('streetId'),
      target: entity('street').property('id'),
      join: 'left',
    })
    [idColumn === 'neighborhoodId' ? 'join' : 'dummy']({
      current: entity('street').property('id'),
      target: entity('neighborhoodStreetIntersection').property('streetId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('countryId'),
      target: entity('country').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        idColumn !== null && id !== null && idColumn === 'neighborhoodId'
          ? equals(entity('neighborhoodStreetIntersection').property(idColumn), string().value(id))
          : null,

        idColumn !== null &&
        id !== null &&
        idColumn !== 'countryIsoCode' &&
        idColumn !== 'neighborhoodId'
          ? equals(entity('legalEntityGeoprocessing').property(idColumn), string().value(id))
          : null,

        between(
          entity('legalEntityAttendance').property('startDate'),
          !filter
            ? entity('dataSource').property('lastYearDate')
            : string().value(filter.startDate),
          !filter ? entity('dataSource').property('endDate') : string().value(filter.endDate),
        ),

        equals(
          entity('legalEntityAttendanceDataSource').property('dataSourceId'),
          number().value(6),
        ),
      ].filter(truthy),
    )
    .limit(10000)
}
