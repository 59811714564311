import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
  geoQueries,
  Level,
} from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import { errors, truthy } from '~/prix'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import useItems from '~/prix/react/hooks/items'
import { GeoJsonMultiPolygon, GeoJsonPoint } from '~/prix/types/geoJson'
import useDisproportionateAttention from '../query/disproportionateAttention.hook'
import { Filter, GeoItem, GeoItemCategory } from './disproportionateAttentionMapLevels.data'
import randomColor from 'randomcolor'
import { eadOptions } from '~/packages/legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenuTypes'

export default function useLegalEntityGeoprocessingPolygonsMap({
  childrenGeoLevel = 'states',
  id = '30',
  by = 'countryId',
  filter,
  isEnabled,
}: {
  childrenGeoLevel:
    | 'macroRegions'
    | 'states'
    | 'mesoRegions'
    | 'microRegions'
    | 'cities'
    | 'neighborhoods'
  by:
    | 'countryId'
    | 'countryIsoCode'
    | 'macroRegionId'
    | 'mesoRegionId'
    | 'stateId'
    | 'microRegionId'
    | 'cityId'
  id: string | null
  filter: Filter | null
  isEnabled: boolean
}) {
  const { google } = useGoogleMaps()
  const [items, setItems] = useState<Array<GeoItem> | null>(null)
  const params = useParams()
  const [level, setLevel] = useState<Level | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [allItems, setAllItems] = useState<Array<GeoItemCategory> | null>(null)
  const [randomSeedNumber, setRandomSeedNumber] = useState<number>()

  useEffect(() => {
    setRandomSeedNumber(Math.random())
  }, [])

  const geoResult = useItems(
    () =>
      isEnabled
        ? ((geoQueries[childrenGeoLevel] as any)(by, id!) as ReturnType<
            (typeof geoQueries)['cities']
          >)
        : (null as never),
    [childrenGeoLevel, id, by, isEnabled],
    { cache: 60 * 60 * 12, autoLoad: isEnabled },
  )

  useEffect(() => {
    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
      return
    }

    setLevel({
      geo: (params.by as GeoLegalEntitiesPossibilities) || 'country',
      id: params.id || '30',
      childrenGeoLevel: params.childrenGeoLevel as GeoChildrenLegalEntitiesPossibilities,
    })
  }, [params])

  const legalEntitiesResult = useDisproportionateAttention({
    filters: {
      dataSourceId: parseInt(filter?.attendanceSource as string) || null,
      // attendancePeriod:
      //   filter?.attendanceSource !== null && filter?.attendancePeriod !== null
      //     ? (filter?.attendancePeriod as 'lastMonth' | 'lastYear')
      //     : null || null,
      attendancePeriod:
        filter && filter.attendanceSource !== null && filter?.attendancePeriod !== null
          ? String(filter.attendancePeriod)
          : null,
      economicActivity: filter?.cnaeId || null,
      size: filter?.size
        ? filter?.size === 'small'
          ? 'EPP'
          : filter?.size === 'micro'
          ? 'ME'
          : filter?.size === 'isMei'
          ? 'MEI'
          : null
        : null,
      eadOption: (filter?.eadOption as keyof typeof eadOptions) || null,
      courseProductCode: (filter?.courseProductCode as unknown as string) || null,
    },
    geoFilter: {
      geoAggregate: level?.childrenGeoLevel || 'states',
      geoLevel: level?.geo || 'country',
      geoLevelId: level?.id || '30',
    },
    isEnabled: isEnabled,
  })

  const bounds = useMemo(() => {
    const items = geoResult.items
    if (!items || !isEnabled) {
      return null
    }
    let south = Infinity
    let west = Infinity
    let north = -Infinity
    let east = -Infinity

    for (const item of items) {
      const multiPolygon = item.boundary as GeoJsonMultiPolygon
      const point = item.center as GeoJsonPoint

      if (multiPolygon && multiPolygon.type === 'MultiPolygon') {
        for (const boundary of multiPolygon.coordinates) {
          const coordinates = boundary[0]
          for (const [longitude, latitude] of coordinates) {
            if (south > latitude) {
              south = latitude
            }
            if (north < latitude) {
              north = latitude
            }
            if (west > longitude) {
              west = longitude
            }
            if (east < longitude) {
              east = longitude
            }
          }
        }
      }
      if (point && point.type === 'Point') {
        const [longitude, latitude] = point.coordinates
        if (south > latitude) {
          south = latitude
        }
        if (north < latitude) {
          north = latitude
        }
        if (west > longitude) {
          west = longitude
        }
        if (east < longitude) {
          east = longitude
        }
      }
    }

    if (!isFinite(south) || !isFinite(north) || !isFinite(east) || !isFinite(west)) {
      return null
    }
    return {
      south,
      west,
      north,
      east,
    }
  }, [geoResult.items, isEnabled])

  const googleBounds = useMemo(() => {
    if (!google || !bounds) {
      return null
    }
    const southWest = new google.maps.LatLng(bounds.south, bounds.west)
    const northEast = new google.maps.LatLng(bounds.north, bounds.east)
    return new google.maps.LatLngBounds(southWest, northEast)
  }, [google, bounds?.east, bounds?.west, bounds?.north, bounds?.south])

  useEffect(() => {
    const geoItems = geoResult?.items
    if (
      !isEnabled ||
      !google ||
      !geoItems ||
      legalEntitiesResult.isLoading ||
      geoResult.isLoading
    ) {
      return
    }
    const attendancesCache: string[] = []
    const publicCache: string[] = []

    const hookResultMapper: {
      public: [GeoItemCategory[]]
      attendance: [GeoItemCategory[]]
    } = {
      public: [[]],
      attendance: [[]],
    }

    setItems(
      geoItems
        .map(geoItem => {
          const centerGeoJson = geoItem.center as { coordinates: number[] } | null

          const publicResult = legalEntitiesResult.publicResult.items?.filter(
            publicItem => publicItem.geoId === geoItem.id,
          )

          const attendanceResult = legalEntitiesResult.attendanceResult.items?.filter(
            attendanceItem => attendanceItem.geoId === geoItem.id,
          )

          const legalEntity = mergeDifferenceRate(
            publicResult as unknown as GeoItemCategory[],
            attendanceResult as unknown as GeoItemCategory[],
            hookResultMapper,
          ) as ReturnType<typeof mergeDifferenceRate>

          let underratedCnaeDescription = 'Sem Informações'

          const topTenCategories: typeof legalEntity = []
          if (legalEntity && legalEntity.length > 0) {
            const mostUnderatedAttendanceCategories = legalEntity.sort((a, b) => {
              if (a!.difference > b!.difference) {
                return -1
              }
              if (a!.difference < b!.difference) {
                return 1
              }
              return 0
            })

            underratedCnaeDescription = mostUnderatedAttendanceCategories[0]
              ? mostUnderatedAttendanceCategories[0].cnaeDescription
              : ''

            topTenCategories.push(...mostUnderatedAttendanceCategories.slice(0, 10))

            if (level?.geo === 'country') {
              attendancesCache.push(...attendanceResult!.map(item => item.cnaeId as string))
              publicCache.push(...publicResult!.map(item => item.cnaeId as string))
            }
          }

          const sliceRandomize = String(topTenCategories[0]?.cnaeId).slice(2, 5)

          const colorTest = randomColor({
            luminosity: 'light',
            hue: 'random',
            seed: sliceRandomize + randomSeedNumber + sliceRandomize,
          })

          const borderColorTest = randomColor({
            luminosity: 'bright', // dark
            hue: 'random',
            seed: sliceRandomize + randomSeedNumber + sliceRandomize,
          })

          //Porcentagens de valor mínimo e máximo para legenda de destaques
          return {
            id: geoItem.id,
            key: geoItem.id,
            name: geoItem.name,
            topTenCategories,
            multiPolygon: geoItem.boundary,
            path: null,
            center: centerGeoJson
              ? new google.maps.LatLng(centerGeoJson.coordinates[1], centerGeoJson.coordinates[0])
              : null,
            color: colorTest,
            percentColor: 0,
            borderColor: borderColorTest,
            count: 0,
            levelKey: childrenGeoLevel,
            geoLevel: childrenGeoLevelToGeoLevel(childrenGeoLevel),
            maxValue: 0,
            legendValues: underratedCnaeDescription,
          }
        })
        .filter(item => item) as unknown as typeof items,
    )

    const upperLevel: any = {
      attendance: [],
      public: [],
    }

    const uniqueCnaesCache = [...new Set(publicCache)]
    for (const cnae of uniqueCnaesCache) {
      try {
        const allPublic = hookResultMapper.public[parseInt(cnae)].reduce((a, b) => {
          return {
            ...a,
            count: parseInt(a.count as unknown as string) + parseInt(b.count as unknown as string),
            geoId: 'all',
          }
        })
        upperLevel.public.push(allPublic)

        const allAttendances = hookResultMapper.attendance[parseInt(cnae)]
          ? hookResultMapper.attendance[parseInt(cnae)].reduce((a, b) => {
              return {
                ...a,
                count:
                  parseInt(a.count as unknown as string) + parseInt(b.count as unknown as string),
                geoId: 'all',
              }
            })
          : null
        upperLevel.attendance.push(allAttendances)
      } catch (err) {
        throw errors.unknown(`Não foi possível calcular atendimento desproporcional a nível país.`)
      }
    }

    const mergeAllDifferentiate = mergeDifferenceRate(
      upperLevel.public.filter(truthy),
      upperLevel.attendance.filter(truthy),
      undefined,
    )
      .sort((a, b) => {
        if (a!.difference > b!.difference) {
          return -1
        }
        if (a!.difference < b!.difference) {
          return 1
        }
        return 0
      })
      .slice(0, 10) as GeoItemCategory[]

    setAllItems(mergeAllDifferentiate)

    setIsLoading(false)
  }, [
    legalEntitiesResult.isLoading,
    JSON.stringify(geoResult?.items),
    geoResult.isLoading,
    google,
    isEnabled,
    randomSeedNumber,
  ])
  return {
    items: isEnabled ? items : null,
    bounds: googleBounds,
    allResults: allItems ? allItems : null,
    isLoading: geoResult.isLoading || legalEntitiesResult.isLoading || isLoading,
    error: geoResult.error,
    queries: isEnabled ? [geoResult.query] : [],
  }
}

/**
 * Converte de childrenGeoLevel para geoLevel (`states -> state`)
 * @param childrenGeoLevel Nível que se deseja converter.
 * @returns GeoLevel.
 */
function childrenGeoLevelToGeoLevel(childrenGeoLevel: string) {
  switch (childrenGeoLevel) {
    case 'states':
      return 'state'
    case 'cities':
      return 'city'
    case 'neighborhoods':
      return 'neighborhood'
  }
}

/**
 * Este método deve fazer a união dos resultados de público com atendimentos para
 * gerar os valores de diferença e ranking de empresas.
 * @param publicResult Array de items do resultado da query de público.
 * @param attendanceResult Array de items do resultado da query de atendimento.
 * @param mapper Para melhorar a performance a nível nacional o mapper é uma variável opcional para guardar os resultados em um array usando `cnaeId` como índice.
 * @returns Um array com o resultado do agrupamento contendo principalmente a diferença de volume entre público e atendimento,
 * porcentagem de público e atendimentos de acordo com a soma de empresas disponíveis nas variáveis de `publicResult`.
 */
export function mergeDifferenceRate(
  publicResult:
    | {
        cnaeId: string
        cnaeDescription: string
        count: number
        geoId: string
      }[]
    | undefined,
  attendanceResult:
    | {
        cnaeId: string
        cnaeDescription: string
        count: number
        geoId: string
      }[]
    | undefined,
  mapper?: { public: [GeoItemCategory[]]; attendance: [GeoItemCategory[]] },
) {
  if (publicResult && attendanceResult) {
    const publicSum = publicResult
      .map(item => item.count)
      .reduce((a, b) => parseInt(a as unknown as string) + parseInt(b as unknown as string), 0)

    const difference = publicResult!
      .map(publicItem => {
        const attendances = attendanceResult.filter(
          attendanceItem => publicItem.cnaeId === attendanceItem.cnaeId,
        )

        if (mapper) {
          if (!mapper.public[parseInt(publicItem.cnaeId)]) {
            mapper.public[parseInt(publicItem.cnaeId)] = [publicItem as GeoItemCategory]
          } else {
            mapper.public[parseInt(publicItem.cnaeId)].push(publicItem as GeoItemCategory)
          }
        }

        if (attendances.length > 0) {
          const attendance = attendances[0]
          const publicRate = publicItem.count / publicSum
          const attendanceRate = attendance.count / publicSum

          if (mapper) {
            if (!mapper.attendance[parseInt(publicItem.cnaeId)]) {
              mapper.attendance[parseInt(publicItem.cnaeId)] = [
                ...(attendances as GeoItemCategory[]),
              ]
            } else {
              mapper.attendance[parseInt(publicItem.cnaeId)].push(
                ...(attendances as GeoItemCategory[]),
              )
            }
          }
          return {
            cnaeId: publicItem.cnaeId,
            cnaeDescription: publicItem.cnaeDescription,
            geoId: publicItem.geoId,
            publicCount: publicItem.count,
            attendanceCount: attendance.count,
            publicRate,
            attendanceRate,
            difference: publicRate - attendanceRate, // A ordenação é feita sobre essa diferença.
          }
        }
      })
      .filter(item => item)

    return difference as {
      cnaeId: string
      cnaeDescription: string
      geoId: string
      publicRate: number
      attendanceRate: number
      publicCount: number
      attendanceCount: number
      difference: number
    }[]
  }
  return []
}
